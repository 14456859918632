class ProgressRing extends HTMLElement {
	constructor() {
		super();
		const stroke = this.getAttribute("stroke");
		const radius = this.getAttribute("radius");
		this.percentage = 0;
		const normalizedRadius = radius - stroke;
		this._circumference = normalizedRadius * 2 * Math.PI;

		this._root = this.attachShadow({ mode: "open" });
		this._root.innerHTML = `
		<svg
		  height="${radius * 2}"
		  width="${radius * 2}"
		  viewBox="0 0 ${radius * 2} ${radius * 2}"
		 >
		   <circle
			 stroke="black"
			 stroke-dasharray="${this._circumference} ${this._circumference}"
			 style="stroke-dashoffset:${this._circumference}"
			 stroke-width="${stroke}"
			 fill="transparent"
			 r="${normalizedRadius}"
			 cx="${radius}"
			 cy="${radius}"
		  />
		</svg>

		<style>
		svg {
			width: 100%;
			height: auto;
			display: block;
		}
		  circle {
			transition: stroke-dashoffset 2s;
			transform: rotate(-90deg);
			transform-origin: 50% 50%;
		  }
		</style>
	  `;
	}

	setProgress() {
		const offset =
			this._circumference - (this.percentage / 100) * this._circumference;
		const circle = this._root.querySelector("circle");
		circle.style.strokeDashoffset = offset;
	}

	static get observedAttributes() {
		return ["progress", "class"];
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (name === "progress") {
			this.percentage = newValue;
		} else if (name === "class" && this.classList.contains("is-visible")) {
			setTimeout((_) => {
				this.setProgress();
			}, 600);
		}
	}
}

function initProgressRings() {
	window.customElements.define("progress-ring", ProgressRing);
}

export { initProgressRings, ProgressRing };
