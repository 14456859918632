import variables from "../../scss/_variables.scss";

export default function updateClampedCSS() {
	const props = new Set(Object.keys(variables).map((k) => k.split("_")[0]));

	props.forEach((p) => {
		const clampString = clampBuilder(
			variables[`${p}_min-width`],
			variables[`${p}_max-width`],
			variables[`${p}_min-fs`],
			variables[`${p}_max-fs`],
		);
		document.documentElement.style.setProperty(p, clampString);
	});
}

function clampBuilder(minWidthPx, maxWidthPx, minFontSizePx, maxFontSizePx) {
	// Passed font sizes are from our designs, designs assume a base font size of 16px
	const minFontSizeRem = minFontSizePx / 16;
	const maxFontSizeRem = maxFontSizePx / 16;

	// Make adjustment for when user has set base font size to something other than 16px
	const root = document.querySelector("html");
	const pixelsPerRem = Number(getComputedStyle(root).fontSize.slice(0, -2));

	const minWidth = minWidthPx / pixelsPerRem;
	const maxWidth = maxWidthPx / pixelsPerRem;

	const slope = (maxFontSizeRem - minFontSizeRem) / (maxWidth - minWidth);
	const yAxisIntersection = -minWidth * slope + minFontSizeRem;

	return `clamp( ${minFontSizeRem}rem, ${yAxisIntersection}rem + ${
		slope * 100
	}vw, ${maxFontSizeRem}rem )`;
}
