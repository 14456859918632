import { AjaxForm } from "@neonjungle/birdseed/forms/ajax";

class SearchForm extends AjaxForm {
	constructor(form, target, errorElement) {
		super(form, target, errorElement);
		this.addSuggestedHandlers();
	}
	addSuggestedHandlers() {
		const queryInput = this.form.querySelector('[name="q"]');
		document
			.querySelectorAll("[data-site-search-suggested]")
			.forEach((suggested) => {
				suggested.addEventListener("click", (e) => {
					e.preventDefault();
					queryInput.value = suggested.textContent;
					this.fetchAndReplace();
				});
			});
	}

	afterFetch(responseJson) {
		super.afterFetch(responseJson);
		this.addSuggestedHandlers();
	}
}
export default function setupSearch() {
	const searchForm = document.querySelector("[data-site-search]");
	if (searchForm) {
		const ajaxSearch = new SearchForm(
			searchForm,
			document.querySelector("[data-site-search-results]"),
		);
		const queryInput = searchForm.querySelector('[name="q"]');
		let timeout;
		const doSearch = (_) => {
			clearTimeout(timeout);
			timeout = setTimeout((_) => {
				ajaxSearch.fetchAndReplace();
			}, 300);
		};
		queryInput.addEventListener("keyup", doSearch);
	}
}
