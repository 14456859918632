function menuActions() {
	const $navTrigger = document.querySelector("[data-navigation-trigger]");
	const $navTarget = document.querySelector("[data-navigation-target]");
	const $mobileMenus = document.querySelectorAll("[data-mobile-submenu]");
	const $searchOverlay = document.querySelector("[data-search-overlay]");
	const $searchTriggers = document.querySelectorAll("[data-search-opener]");
	let searchOpen = false;
	let menuOpen = false;

	const searchToggle = (_) => {
		searchOpen = !searchOpen;
		$searchTriggers.forEach(($trigger) => {
			$trigger.classList.toggle("is-active");
		});
		document.body.classList.toggle("search-open");
		$searchOverlay.classList.toggle("is-open");
		if (searchOpen) {
			document.querySelector("#id_q").focus();
		}
	};

	const menuToggle = (_) => {
		menuOpen = !menuOpen;
		$navTrigger.classList.toggle("is-active");
		$navTarget.classList.toggle("is-active");
		document.body.classList.toggle("menu-open");

		$mobileMenus.forEach(($submenu) => {
			$submenu
				.querySelector("[data-mobile-submenu-list]")
				.classList.remove("is-active");
		});
	};

	if ($navTrigger && $navTarget) {
		$navTrigger.addEventListener("click", (e) => {
			menuToggle();
			if (menuOpen && searchOpen) {
				searchToggle();
			}
			e.preventDefault();
		});

		const $navLinks = $navTarget.querySelectorAll(
			"a:not([data-mobile-submenu-opener])",
		);
		$navLinks.forEach(($l) => {
			$l.addEventListener("click", (e) => {
				$navTrigger.classList.toggle("is-active");
				$navTarget.classList.toggle("is-active");
				document.body.classList.remove("menu-open");
			});
		});
	}

	if ($mobileMenus) {
		$mobileMenus.forEach(($submenu) => {
			const $trigger = $submenu.querySelector("[data-mobile-submenu-opener]");
			const $target = $submenu.querySelector("[data-mobile-submenu-list]");

			$trigger.addEventListener("click", (e) => {
				$target.classList.toggle("is-active");
				// FIXME Not great, basically want the links to work on desktop but not mobile
				// 85 rem ~ $bp-large when compiled
				if (window.matchMedia("screen and (max-width: 85.6rem)").matches) {
					e.preventDefault();
				}
			});
		});
	}
	$searchTriggers.forEach(($trigger) => {
		$trigger.addEventListener("click", (e) => {
			e.preventDefault();
			searchToggle();
			if (searchOpen && menuOpen) {
				menuToggle();
			}
		});
	});
}
export default menuActions;
