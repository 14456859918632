import "intersection-observer";
import scrollama from "scrollama";

const debug = false;

function isMobile() {
	const breakPointSize = getComputedStyle(
		document.documentElement,
	).getPropertyValue("--mobile-bp");
	return window.matchMedia(`(max-width: ${breakPointSize})`)["matches"];
}

export default function animations() {
	if (isMobile()) {
		// Skip prose block animations for mobile
		document
			.querySelectorAll(
				".l-prose-blocks__block, .circle-stat__progress, [data-section-target]",
			)
			.forEach((el) => {
				el.classList.add("is-visible");
			});
	} else {
		if (document.querySelector(".l-prose-blocks")) {
			proseBlocks();
		}
		if (document.querySelector(".circle-stat__progress")) {
			progressRing();
		}
		if (document.querySelector(".l-home-fold")) {
			homeScrollTracker();
		}
	}
}

export function proseBlocks() {
	const scroller = scrollama();
	const $sections = document.querySelectorAll(".l-prose-blocks__block");
	scroller
		.setup({
			step: $sections,
			offset: 0.9,
			once: true,
			debug: debug,
		})
		.onStepEnter((res) => {
			res.element.classList.add("is-visible");
		});

	window.addEventListener("resize", scroller.resize);
}

export function progressRing() {
	const scroller = scrollama();
	const $rings = document.querySelectorAll(".circle-stat__progress");

	scroller
		.setup({
			step: $rings,
			offset: 0.9,
			once: true,
		})
		.onStepEnter((res) => {
			res.element.classList.add("is-visible");
		});

	window.addEventListener("resize", scroller.resize);
}

function homeScrollTracker() {
	const $container = document.querySelector("[data-section-container]");
	const $triggers = Array.from(
		document.querySelectorAll("[data-section-trigger]"),
	);
	const $sections = document.querySelectorAll("[data-section-target]");

	const animationScroller = scrollama();

	animationScroller
		.setup({
			offset: 0.85,
			step: $sections,
			debug: debug,
		})
		.onStepEnter((res) => {
			res.element.classList.add("is-visible");
		});

	const sidebarScroller = scrollama();

	sidebarScroller
		.setup({
			step: $sections,
			debug: debug,
		})
		.onStepEnter((res) => {
			$triggers.forEach(($s) => $s.classList.remove("is-active"));
			$triggers
				.filter(($t) => $t.dataset.sectionTrigger === res.element.id)[0]
				.classList.add("is-active");
			$container.classList.remove("t-light", "t-dark");
			$container.classList.add(`t-${res.element.dataset.colorScheme}`);
		});

	const videoScroller = scrollama();

	videoScroller
		.setup({
			offset: 0.5,
			step: $sections,
			debug: debug,
		})
		.onStepEnter((res) => {
			let vid = res.element.querySelector("video");
			if (vid) {
				vid.play();
			}
		})
		.onStepExit((res) => {
			let vid = res.element.querySelector("video");
			if (vid) {
				vid.pause();
			}
		});

	$triggers.forEach(($t) => {
		$t.addEventListener("click", (e) => {
			document
				.querySelector(`#${$t.dataset.sectionTrigger}`)
				.scrollIntoView({ behavior: "smooth" });
			e.preventDefault();
		});
	});

	window.addEventListener("resize", sidebarScroller.resize);
	window.addEventListener("resize", animationScroller.resize);
	window.addEventListener("resize", videoScroller.resize);
}
